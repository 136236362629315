import React, {useEffect,useState} from 'react';

// components
import TemplateMenu from '../../Components/TemplateMenu';
import Loader from '../../Components/Loader';
import DataGrid,{dgDataPrepare} from '../../Components/DataGrid';
import NotFound from '../../Components/NotFound';

// models
import {Orders} from './../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';
import {empty} from '../../Globals/Utils';

// globals
import {orderStatusName,orderStatus} from '../../Globals/Constants';

const HistoriesScreen = () => {
	const [dataFull, setDataFull] = useState(null);
	const [orders, setOrders] = useState(null);
	const [status, setStatus] = useState(orderStatus);
	useEffect(() => {
		const orderGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			const d = await Orders.getFinish(user.cityId);
			d.forEach((v) => {
				v.priceFull = v.parts[0].products.reduce((a, v) => a + (v.priceDiscount||v.price) * v.quantity, 0);
			});
			setOrders(dataSet(d));
			setDataFull(d);
		};
		orderGet();
	}, []);
	const dataSet = (data) => dgDataPrepare(data, 'orders', ['_id','createdAt','paymentId','status'], 'orders');
	const dataStatus = (data, status) => data.filter(f => f.status === status);
	const handleStatus = (e) => {
		let status = e.target.value;
		let d = dataFull;
		if (empty(status)) status = null;
		if (status !== null) d = dataStatus(d, parseInt(status));
		setOrders(dataSet(d));
		setStatus(status);
	}
	return (
		<TemplateMenu>
			{orders === null ? <Loader /> :
				<DataGrid
					title={'История заказов'} 
					keys={['Дата','Номер заказа','Статус']}
					link={'history'}
					data={orders}
					dataFull={dataFull}
					controlPanel={<>
						<div></div>
						<div>
							<span>Статус</span>
							<select onChange={handleStatus}>
								<option value=" ">все</option>
								{orderStatusName.map((v,i) => i === 0 ? null : <option key={i} value={i}>{v}</option>)}
							</select>
						</div>
					</>}
					notFound={<NotFound />} />
			}
		</TemplateMenu>
	);
};

export default HistoriesScreen;