import Http from '../../Globals/Http';

const getActive	= async (cityId)			=> await Http.request(`cargouser/orders/active/${cityId}`);
const getFinish	= async (cityId)			=> await Http.request(`cargouser/orders/finish/${cityId}`);

const getById	= async (userId, orderId)	=> await Http.request(`cargouser/order/${userId}/${orderId}`);

const ready		= async (id, partnerId)		=> await Http.request('cargouser/order/ready', {orderId:id,partnerId});
const finish	= async (id, partnerId)		=> await Http.request('cargouser/order/finish', {orderId:id,partnerId});
const courier	= async (id, partnerId)		=> await Http.request('cargouser/order/finish/courier', {orderId:id,partnerId});


export {
	getActive,
	getFinish,
	getById,
	ready,
	finish,
	courier
}