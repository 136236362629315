import React, {useEffect,useState} from 'react';

// plug-ins
import Moment from 'moment';

// components
import TemplateMenu from '../../Components/TemplateMenu';
import Loader from '../../Components/Loader';
import Back from '../../Components/Back';
import NotFound from '../../Components/NotFound';

// models
import {Orders} from './../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';
import {orderNumberGet,phoneFormatter} from '../../Globals/Utils';

// globals
import {orderPartsStatusName} from '../../Globals/Constants';

// styles
import './styles.css';

const OrderScreen = (props) => {
	const [loading, setLoading] = useState(true);
	const [id] = useState(props.match.params.id||0);
	const [order, setOrder] = useState(null);
	useEffect(() => {
		const orderGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			const order = await Orders.getById(user._id, id).catch((ex) => console.log(666, ex));
			setOrder(order);
			setLoading(false);
		};
		orderGet();
	}, []);
	return (
		<TemplateMenu>
			{loading ? <Loader /> :
				<div>
					<Back title="Иформаиция о заказе" link='histories' />
					{order === null ? <NotFound /> :
						<>
							<div className="form-container">
								<div className="cell">
									<label>
										<span>Номер заказа</span>
										<div>№ <b>{orderNumberGet(order.paymentId)}</b></div>
									</label>
								</div>
								<div className="cell-oneline">
									<div className="cell">
										<label>
											<span>Статус</span>
											<div>{orderPartsStatusName[order.parts.status]}</div>
										</label>
									</div>
									<div className="cell">
										<label>
											<span>Принято</span>
											<div>в {Moment(order.createdAt).format('HH:mm, DD.MM.YYYY')}</div>
										</label>
									</div>
								</div>
								<div className="cell-oneline">
									<div className="cell">
										<label>
											<span>Город доставки</span>
											<div>{order.address.city}</div>
										</label>
									</div>
									{order.point ?
											<div className="cell">
												<label>
													<span>Доставка в ПВЗ</span>
													<div>г. {order.point.cityName}, {order.point.address}</div>
												</label>
											</div>
										:
											<div className="cell">
												<label>
													<span>Доставка курьером</span>
													<div>—</div>
												</label>
											</div>
									}
								</div>
							</div>
							<div className="form-container">
								<h4>Состав заказа</h4>
								{order.parts.products.map((v,i) => <div key={i} className="row">
									<div>
										<span className="category">{v.category}</span>
										<span className="item-name">{v.name}</span> {v.weight ? <span className="item-weight">{v.weight} г.</span> : null}
									</div>
									<div>{v.quantity||1} шт</div>
								</div>)}
							</div>
						</>
					}
				</div>
			}
		</TemplateMenu>
	);
};

export default OrderScreen;